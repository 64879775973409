.face, .face * {
    border-radius: 50%;
}

.face.positive {
    background-color: #00C327;
}

.face.mediocre {
    background-color: #E1A500;
}

.face.negative {
    background-color: #E13700;
}

.eye {
    position: absolute;
    width: 15%;
    height: 15%;
    top: 25%;
    background-color: black;
}

.eye.left {
    left: 20%;
}

.eye.right {
    right: 20%;
}

.mouth {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 60%;
    border: 1vmin solid transparent;
}

.mouth.positive {
    top: 50%;
    border-bottom-color: #000;
}

.mouth.mediocre {
    width: 60%;
    border-width: 1vmin 0 0 0;
    border-radius: 0;
    border-top-color: #000;
}

.mouth.negative {
    border-top-color: #000;
}