*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;

  overflow-x: hidden;
  overflow-y: hidden;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
