.ticker {
  width: 100%;
}

.ticker::before,
.ticker::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5vw;
}

.ticker::before {
  left: 0;
  z-index: 100;
}

.ticker::after {
  right: 0;
  z-index: 100;
}
