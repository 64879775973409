.html-text * {
    overflow-wrap: break-word;
    margin: 0;
}

.html-text h1 {
    font-size: 8vmin;
    line-height: 9vmin;
}

.html-text h2 {
    font-size: 7vmin;
    line-height: 7.5vmin;
}

.html-text h3 {
    font-size: 6vmin;
    line-height: 6.5vmin;
}

.html-text h4, h5, h6 {
    font-size: 5.5vmin;
    line-height: 6vmin;
}

.html-text p {
    font-size: 4.5vmin;
    line-height: 5.5vmin;
}