.fade-in, .fade-out, .zoom-out, .roll-in, .blur-out {
    width: 100%;
    height: 100%;
}

.fade-in {
    animation: fade 1s;
}

.fade-out {
    animation: fade 1s reverse;
    opacity: 0;
}

.zoom-out {
    animation: zoom 1s ease-in-out forwards;
}

.roll-in {
    animation: roll 1s forwards;
}

.blur-out {
    animation: blur 1s forwards;
}

@keyframes fade {
    0% {
        opacity: 0;
        transform: translateY(5vmin);
        filter: blur(2vmin);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        filter: blur(0);
    }
}

@keyframes zoom {
    0% {
        transform: scale(1.05);
        opacity: 0;
        filter: drop-shadow(0 20px 10px rgba(0,0,0, 0.1));
    }
    100% {
        transform: scale(1.00) translateZ(0);
        opacity: 1;
        filter: drop-shadow(0 0 0 rgba(0,0,0, 0));
    }
}

@keyframes roll {
    0% {
        opacity: 0;
        transform: rotate(-60deg);
        filter: blur(2vmin);
    }
    100% {
        opacity: 1;
        transform: rotate(0) translateZ(0);
        filter: blur(0);
    }
}

@keyframes blur {
    0% {
        opacity: 0;
        filter: blur(2vmin);
    }
    100% {
        opacity: 1;
        filter: blur(0);
    }
}